import React, { Component } from 'react';
import { Row, Form, Col, Container, Navbar, Nav} from 'react-bootstrap';
import fairChase from '../images/Fair_Chase_text.png';
class Footer extends Component {
    render() {
        return (
            <Navbar id="IGL_footer_wrapper" className="navbar navbar-dark bottom " expand="lg">
                <Container >
                 <Row>
                 <img src={fairChase} alt="Fair Chase" />
                 </Row>
                 <Row id="IGL_footer">
                 <a href="#banner" title="Home">Home</a> <span className="Horzbar">| </span> <a href="#Hunting" title="Deer Hunting">Deer</a> <span className="Horzbar">| </span> <a href="#Duck" title="Duck Hunting">Ducks</a> <span className="Horzbar">| </span> <a href="#Turkey" title="Turkey Hunting">Turkey</a> <span className="Horzbar">| </span> <a href="#Fishing" title="Fishing">Fishing</a> <span className="Horzbar">| </span> <a href="#Gallery" title="Trophy Photos and Video">Trophy Gallery</a>  <span className="Horzbar">| </span> <a href="#Gallery" title="Lodge Photos">The Lodge</a> <span className="Horzbar">| </span> <a href="#Paypal" title="Pay your Deposit via Paypal">Payments</a><a href="#Contact" title="Contact Us"></a> <span className="Horzbar">| </span> <a href="#Map" title="Map and Directions">Location</a> <span className="Horzbar">| </span> <a href="#Partners" title="Partners and Sponsers">Partner Links</a> <span className="Horzbar">| </span>  <a href="#Contact">Contact Us</a> <span className="Horzbar">| </span> <a href="#References" title="Hunting References">References</a>
                 </Row>
             </Container>
            </Navbar>
        )
    }

}
export default Footer;