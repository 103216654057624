import React from 'react';
import ReactDOM from 'react-dom';
import {Router, Switch, Route} from 'react-router-dom';
import createBrowserhistory from 'history/createBrowserHistory';
import './index.css';
import Header from './components/Header';
import App from './components/App';
import Gallery from './components/Gallery';
import * as serviceWorker from './serviceWorker';

const history = createBrowserhistory();
ReactDOM.render(

<Router history={history}>
    <Switch>
        <Route exact path='/' render={() => <Header ><App /></Header>}></Route>
    </Switch>
</Router>, 

document.getElementById('root'));
// ReactDOM.render(

//     <App />, 
    
//     document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
