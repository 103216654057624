import React from 'react';
import {Link} from 'react-router-dom';
import {  Navbar, Nav } from 'react-bootstrap';
import logo from '../images/IGLLogoPlain.png'
const Header = ({children}) =>
{

    return(
        <div>
        <Navbar className="navbar navbar-dark  fixed-top" expand="lg">
                <Navbar.Brand href="#banner"><img src={logo} width='100' height='50'/></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end" >
                    <Nav className="mr-sm-2">
                        <Nav.Link href="#banner">Home</Nav.Link>
                        <Nav.Link href="#Hunting">Hunting</Nav.Link>
                        <Nav.Link href="#Gallery">Gallery</Nav.Link>
                        <Nav.Link href="#Contact">Contact</Nav.Link>
                    </Nav>
                    <a href="https://www.facebook.com/Iron-Gate-Lodge-67979487975/" target="_blank" className="fa fa-facebook justify-content-end"></a>
                </Navbar.Collapse>
            </Navbar>
        {children}
        </div>
    )
}

export default Header;