import React, { Component } from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import duck from '../images/duck2.png';
import fish from '../images/bass2.png';
class Hunting extends Component {
    render() {
        return (
            <div className="contContainer center-block">
                <Container id="Deer">
                    <Row>
                        <h2>Hunting</h2>
                    </Row>
                    <Row>
                        <h3 >Deer</h3>
                    </Row>
                    <Row>
                        <p>Iron Gate Lodge operates in Unit 11 and manages several different hunting areas covering more than 3,000 acres in Southeastern Kansas, 100% fair chase. These farms are owned by us and leased and since 2003 when we started we have kept narrowing down the leases to only the best of the best in our portfolio. Our stands consist of mostly 15’ ladder stands, but, we also have tripods, ground blinds, and shooting houses. With over forty Deer Stands preset and the ability to pop up a blind at any time, we can move around to and find the deer movements to match the conditions.</p>
                        <p>You will arrive the afternoon before and leave out the morning after your scheduled hunt dates. We provide all meals, lodging, and transportation around the hunting areas. You are responsible for your deer tag and hunting license. The Kansas Deer Tag Application Deadline is typically in late April (we will help you apply when the time comes.)  All of our hunts are the same price regardless of seasons. Every time you go afield you have a chance to kill that buck of a lifetime and there are no additional trophy fees. We offer only free range, fair chase hunts providing you with an opportunity in some of the best farms in the Midwest. </p>
                        <ul>
                            <li>5-day Rifle and Muzzleloader Whitetail Hunt $4,500</li>
                            <li>6-day Archery Whitetail Hunt $4,200 - If no blood is drawn during your archery hunt $1000 of your hunt fee will be applied towards the next years deposit. </li>
                        </ul>
                        <p>A Trophy is in the eye of the beholder and we will honor your harvest respectfully, however, we strive to harvest only mature bucks (3.5 years.)  We have taken 3.5 years old deer at 186&quot; B&amp;C and 4.5 years old 198&quot; B&amp;C bucks. We have also harvested 6.5 years old 105&quot; bucks. </p>
                        <p>If no blood is drawn during your <strong>Archery</strong> <strong>Hunt</strong>, <strong>we will take $1000</strong> and roll to the next years deposit. This $1000 rollover must be used in the next calendar year. If you kill  a deer over 150&quot; you will a receive a $500 refund. This is our reward program to keep you from shooting young  deer. <strong>Other outfitters charge you a penalty, we give you money back!! </strong>(This offer is only for Archery Hunts at this time.)</p>
                        <p>We offer full care of your animal after you harvest it. We have a walk in cooler to keep your animal and meat cool until we are ready to break your animal down. We will skin, cape, quarter, or debone your meat and pack into your cooler. Or if time allows we have a local processor in town that can custom process it and wrap it any way you would like.</p>
                    </Row>
                    <div id="duckImg"><img src={duck} alt="Duck" /></div>
                </Container>
                <Container id="Duck">
                    <Row>
                        <h3 >Duck</h3>
                    </Row>
                    <Row>
                        <p>Iron Gate Lodge is located in a major local flyway with a good to excellent flight of birds in most years. We have access to 100s of acres of strip pit waterways and thousands of acres of fields to hunt in.  We have deep water to hunt when the potholes freeze over as well as shallow water areas preferred by mallards. We have numerous wetlands built with drain structures that we drain every spring and plant just for waterfowl. We flood these in the fall and the mallards flock in the 1000's. Duck hunts are done on a limited basis only at peak conditions. We will notify you when that occurs and get you here short notice to hunt. Our duck hunts include lodging and evening meals. </p>
                        <ul>
                            <li>Season long duck leases are offered ranging from $5,000 a year to $20,000 a year.</li>
                            <li>Occasionally we will do some day hunts priced at $250 a day per gun.</li>
                        </ul>
                    </Row>
                </Container>
                <Container id="Turkey">
                    <Row>
                        <h3>Turkey</h3>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <p>Iron Gate Lodge has an abundance of Eastern, Rio Grande and Hybrid Wild Turkeys for you to hunt in the spring.  Our birds tend to be very vocal and lots of fun to hunt along the river, small creeks, or in the strip pits.  Spring Turkey Hunts are Gobbler only and include Lodging and evening meals.  A guide/caller can be available for an additional fee.</p>

                            <ul>
                                <li>3-day Turkey Hunt $750 (additional days $200) </li>
                                <li>Limit two Turkeys</li>
                            </ul>
                            <h4>More Hunting Information:</h4>
                            <ul>
                                <li>Airport pickup can be arranged upon request</li>
                                <li>A 50% non refundable payment made upon booking is  required</li>
                                <li>You must obtain your own nonresident hunting  license/tag</li>
                                <li>License may be purchased at <a href="https://ksoutdoors.com/License-Permits" target="_blank" title="Obtain license ">https://ksoutdoors.com/License-Permits</a></li>
                            </ul>
                        </Col>
                        <Col xs={12} md={6}>
                        <div className="container-fluid">
                        <div className="map-responsive">
                            <iframe src="http://player.vimeo.com/video/60899080?portrait=0&amp;color=90782c" width="500" height="281" frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen={true}></iframe> 
                            </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container id="Fishing">
                    <Row>
                        <h3>Fishing</h3>
                    </Row>
                    <Row>
                        <p>At Iron Gate Lodge, on our private 125 acres of water, the fishing is truly amazing all year long.  We have stocked these strip pits with Florida Bass, Largemouth Bass, Crappie, Channel Cat, Redear, Hybrid Bluegill, and Fathead Minnows to fish for.  Our main body of water is approximately 85 acres and we have a concrete boat ramp for easy boat launching. This pit consists of over 7 miles of shoreline with various depths from 1’ deep flats to 30’ deep water for your fishing preference.  Fishing trips include lodging and evening meals.</p>
                        <h4>Fishing Rates</h4>
                        <ul>
                            <li>1-year Family Fishing Membership (lodging or meals excluded) $500</li>
                            <li>1-year Two Person “Buddy” Fishing Membership (lodging or meals excluded) $750</li>
                            <li>1-year Family Fishing Membership with camping rights $750</li>
                            <li>1-day Fishing Pass (lodging or meals excluded) $100 per person</li>
                        </ul>
                        
                    </Row>
                    <div id="fishImg"><img src={fish} alt="fish" /></div>
                </Container>
            </div>

        )
    }
}

export default Hunting;