import React, { Component } from 'react';
import { SLIDER_IMGS } from '../data/flickrUrls'
import { Row, Container } from 'react-bootstrap';
class Welcome extends Component {
    render() {
        return (
            <div id="test" className="contContainer center-block">
                <Container>
                    <Row>
                    
                        <h3>Welcome to Iron Gate Lodge</h3>
                    </Row>
                    <Row>
                        <summary>Iron Gate Lodge is located in southeast Kansas along the Neosho River near Oswego, KS.</summary><p>We manage over 3,000 acres of fair chase
                            hunting areas that are privately owned and hold many trophy bucks. With access in two counties (Labette and Cherokee), we can keep you in an area
                        that has low hunting pressure.</p>
                    </Row>
                    <Row>
                        <summary>The habitat of the farms in the region is diverse.</summary> <p> Terrain with abundant agriculture, bottomland fields, thick draws, woodlots,
                            hardwood ridges, and overgrown pastures.  Also, we have the unique and famous strip pits from retired mining operations that offer an excellent
                            hunting opportunity.  The local area has the highest deer population in the entire state and Kansas has more 160” Pope &amp; Young bucks in the
                        books than any other state in the nation. We are one of the only outfitter in this part of the state that have multiple farms adjoining the Famous
                         Kansas Army Ammunition Plant. This 14,000 acre facility harbors some of the biggest bucks around, many of these deer that die of old age.</p>
                    </Row>
                    <Row>
                        <summary>The deer herd has great genetics and abundant food sources.</summary> <p>We supplement the population with feeders and bi-seasonal food plots.
                            Our whitetails have all it takes to grow Boone &amp; Crockett racks once they mature!  In 2005, we sighted a huge buck during the summer
                        only to find him dead that winter. A sad end to a buck we were trying to harvest. This buck was only 4 yrs old, yet officially scored 198 4/8”. 
                        We have killed typical bucks pushing the 200 inch mark. </p>
                        <p id="book"><span>When you book with Iron Gate Lodge, we will do everything we can to make your hunt a success. You will be welcome in our home and eat at our kitchen
                             table. We are a small family outfitter with limited availability that makes for a relaxing environment. While hunting with Iron Gate Lodge you will feel
                          just like one of the family.</span> </p>
                    </Row>
                    <Row>
                        <ul id="imgSlider" className="cS-hidden" >
                            {
                                SLIDER_IMGS.map((img) => {
                                    return (
                                        <li key={img.id} className="item-a"> <img src={img.src} className="img-thumbnail item-a" alt={img.title} width="100%" height="auto" /></li>
                                    )
                                })
                            }
                        </ul>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default Welcome;

