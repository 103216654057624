import React, { Component } from 'react';
import Footer from './Footer';
import Banner from './Banner';
import { Row, Form, Col, Container, Image } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel'
class Gallery extends Component {
    render() {

        return (
            <div className="contContainer center-block">
                <Container id="Gallery">

                <Row>
                        <h2>Photo Gallery</h2>
                    </Row>
                    <Row>
                        <h3>Deer & Turkey</h3>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                        <iframe className="frame1" src="https://flickrembed.com/cms_embed.php?source=flickr&layout=responsive&input=72157632508266995&sort=0&by=album&theme=default&scale=fit&speed=3000&limit=15&skin=default&autoplay=false" scrolling="no" frameborder="0" allowFullScreen={true} webkitallowfullscreen="true" mozallowfullscreen="true"><p>Powered by <a href="https://themesort.com">themesort WordPress themes</a> here</p><small>Powered by <a href="https://flickrembed.com">flickr embed</a>.</small></iframe>
                        </Col>
                    </Row>
                    <Row>
                        <h3>Trail Cam</h3>
                    </Row>
                    <Row>
                    <iframe className="frame1" src="https://flickrembed.com/cms_embed.php?source=flickr&layout=responsive&input=72157630686091020&sort=3&by=album&theme=compact&scale=fill&speed=3000&limit=20&skin=default&autoplay=false" scrolling="no" frameborder="0" allowFullScreen={true} webkitallowfullscreen="true" mozallowfullscreen="true"><p>Powered by <a  href="https://themesort.com">themesort WordPress themes</a> here</p><small>Powered by <a href="https://flickrembed.com">flickr embed</a>.</small></iframe>
                    </Row>
                    <Row>
                        <h3>The Lodge</h3>
                    </Row>
                    <Row>
                    <iframe className="frame2" src="https://flickrembed.com/cms_embed.php?source=flickr&layout=responsive&input=72157710756379208&sort=2&by=album&theme=tilesgrid&scale=fill&speed=3000&limit=10&skin=default&autoplay=false" scrolling="no" frameborder="0" allowFullScreen={true} webkitallowfullscreen="true" mozallowfullscreen="true"><p>Powered by <a  href="https://themesort.com">themesort WordPress themes</a> here</p><small>Powered by <a href="https://flickrembed.com">flickr embed</a>.</small></iframe>
                    </Row>
                </Container>
            </div>
        )
    }
}
export default Gallery;