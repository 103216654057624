import React, { Component } from 'react';
import { Row, Form, Col, Container } from 'react-bootstrap';
import phone from '../images/iphone_48.png';
import email from '../images/mail_48.png';
import house from '../images/home_48.png';
import mossyOak from '../images/MossyOak-Properties.png'
import coppergate from '../images/partnerCoppergate.fw.jpg'
import scentMaster from '../images/partnerScentMaster.jpg'
class Contact extends Component {
    render() {
        var date = new Date();
        const today = date.toISOString().substr(0, 10);
        return (
            <div className="contContainer center-block">
                <Container>
                <h2>Contact Us</h2>
                <Row>
                    <img src={phone} alt="Call" width="48" height="45" />
                    <h3 className="contactHead">Josh<a href="tel:620-870-9766">(620)870-9766 </a>
                        </h3>
                </Row>
                <Row>
                    <img src={email} alt="email" width="48" height="45" />
                    <h3 className="contactHead"> Email<a href="mailto:jessnjosh@ckt.net"> Josh and Jessica Fiscus </a></h3>
                </Row>
                <hr></hr>
                <Row>
                    <img src={house} alt="email" width="48" height="45" />
                    <h3 className="contactHead"> Location</h3>
                    <p>12740 NW Belleview Rd, Oswego, KS 67356</p>
                    <div className="container-fluid">
                        <div className="map-responsive">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.566224354862!2d-95.06295003332957!3d37.23750690463755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87b80083d84f8b8f%3A0x949da76f5e98f124!2s12740%20NW%20Belleview%20Rd%2C%20Oswego%2C%20KS%2067356!5e1!3m2!1sen!2sus!4v1566672935851!5m2!1sen!2sus" width="600" height="450" frameBorder="0" allowFullScreen=""></iframe>
                        </div>
                    </div>
                </Row>
                <hr />
                <Row>
                    <h3 className="contactHead"> Paypal</h3>
                    <p>Complete the form and click Pay Now if you would like topay your deposit using Paypal</p>

                </Row>
                <Form action="https://www.paypal.com/cqi-bin/webscr" method="post">
                    <input type="hidden" name="item_number" value="Iron Gate Lodge - Deposit" />
                    <input type="hidden" name="cmd" value="_xclick" />
                    <input type="hidden" name="no_note" value="1" />
                    <input type="hidden" name="business" value="jessnjosh@ckt.net" />
                    <input type="hidden" name="currency_code" value="USD" />
                    <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Form.Label column sm="2" name="on0">
                            Your Name
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Form.Label column sm="2">
                            Payment Amount
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="number" name="amount" min="1.00" max="25000" />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Form.Label column sm="2">
                            Hunt Dates
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="date" defaultValue={today} name="on1" />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <input type="image" src="https://www.paypal.com/en_US/i/btn/btn_paynowCC_LG.gif" name="submit" alt="PayPal - The safer, easier way to pay online!" />
                        <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                    </Form.Group>

                </Form>
                <hr />
                <Container>
                {/* <Row>
                    <h3 className="contactHead">Partner Links</h3>
                    <p>Josh Fiscus from Iron Gate Lodge has teamed up with Mossy Oak Properties as an agent,
                        If you are looking for hunting property in Kansas contact Josh drectly or the <a href="http://www.mossyoakproperties.com/Property/AgentDetails.aspx?person_id=5209">Mossy oak Properties</a>
                    </p>
                    <table width="100%" border="0" align="center" cellPadding="1" cellSpacing="10" bgcolor="#FFFFFF" summary="Partners">
                        <tbody>
                        <tr >
                            <td colSpan="2" align="center"><a href="http://www.mossyoakproperties.com/Property/viewOffice.aspx?company_id=196" target="_blank"><img src={mossyOak} className="img-fluid" alt="Josh at Mossy Oak Properties" /></a></td>

                        </tr>
                        <tr>
                            <td align="center"><a href="http://scentmasterbox.com" target="_blank"><img src={scentMaster} className="img-fluid" alt="ScentMaster" /></a></td>
                            <td align="center"><a href="http://www.huntingtrophy.com/"><img src={coppergate} className="img-fluid" alt="Customs Broker through Coppergate Global Logistics" /></a></td>
                        </tr>
                        </tbody>
                    </table>

                </Row> */}
                </Container>
                <hr />
                <Container id="References">
                <Row>
                <h3>References</h3>
                    <h4>Below is a list of hunting references for your convenience. We have tried to supply names from various locations around the country with reputable hunters that have been here numerous times.</h4>
                </Row>
                        <Row>
                            <Col><p>Jason Cloninger, NC,  Muzzle loader </p>
                                <p>(704) 361-7797</p>
                                <p><a href="mailto:cloningersinc@bellsouth.net">cloningersinc@bellsouth.net</a></p></Col>
                            <Col><p>David Adams, VA,  Turkey</p>
                                <p>(434) 251-0014</p>
                                <p><a href="mailto:david.l.adams.62@gmail.com">david.l.adams.62@gmail.com</a></p></Col>
                        </Row>
                        <Row>
                            <Col><p>Keith Amos, MO, Turkey</p>
                                <p>(573) 473-4534</p>
                                <p><a href="mailto:keithallenamos@gmail.com">keithallenamos@gmail.com</a></p></Col>
                            <Col><p>Kyle Coats, MS,  Archery</p>
                                <p>(601) 498-1314</p>
                                <p><a href="mailto:kcoats@bayspringstel.net">kcoats@bayspringstel.net</a></p></Col>
                        </Row>
                        <Row>
                            <Col><p>Matt Hovis, NC,  Muzzle loader</p>
                                <p><a href="mailto:matt@neillgrading.com">matt@neillgrading.com</a></p></Col>
                            <Col><p>Steve Keys, SC</p>
                                <p>(803) 513-9413</p>
                                <p><a href="mailto:steven.keys@uslawnssc.net">steven.keys@uslawnssc.net</a></p></Col>
                        </Row>
                        <Row>
                            <Col><p>Jim Thompson, GA,  Rifle</p>
                                <p>(706) 473-0570</p>
                                <p><a href="mailto:jimthompson@plantationcable.net">jimthompson@plantationcable.net</a></p></Col>
                            <Col><p>Greg Wood, FL, Archery</p>
                                <p>(904) 910-0593</p>
                                <p><a href="mailto:greg@bigcountrystudios.com">greg@bigcountrystudios.com</a></p></Col>
                        </Row>
                        <Row>
                            <Col><p>Kris Reid, WA, Archery</p>
                                <p>(360) 661-4774</p>
                                <p><a href="mailto:krisreid1@mac.com">krisreid1@mac.com</a></p>
                                <p></p></Col>
                            <Col></Col>
                        </Row>
                </Container>
                </Container>
            </div>
        )
    }
}

export default Contact;