export const  BANNERIMGS = [
    {
        id: 1,
        title: "Wayne Corbitt",
        src: "https://live.staticflickr.com/8211/8375969615_ed61e00298_o.jpg"

    },
    {
        id: 2,
        title: "Role Davenfront",
        src: "https://live.staticflickr.com/8492/8375969885_15f5ae8487_o.jpg"
    },
    {
        id: 3,
        title: "Josh Fiscus",
        src: "https://live.staticflickr.com/8503/8377044266_3754fb17b0_o.jpg"
    },
    {
        id: 4,
        title: "Wayne Corbitt",
        src: "https://live.staticflickr.com/8502/8375969403_46699b94c1_o.jpg"
    },
    {
        id: 5,
        title: "Pat Corbitt",
        src: "https://live.staticflickr.com/8235/8375969645_f887ac2ed2_o.jpg"
    }



]

export const SLIDER_IMGS = [
    {
        id: 1,
        title: "deer",
        src:"./gallery/full_cam/11-1.jpg"
    },
    {
        id: 2,
        title: "deer",
        src:"./gallery/full_cam/Buck_47.jpg" 
    },
    {
        id: 3,
        title: "deer",
        src:"./gallery/full_cam/Buck_46.jpg"
    },
    {
        id: 4,
        title: "deer",
        src:"./gallery/full_cam/Buck_45.jpg"
    },
    {
        id: 5,
        title: "deer",
        src:"./gallery/full_cam/Buck_44.jpg"
    },
    {
        id: 6,
        title: "deer",
        src:"./gallery/full_cam/Buck_43.jpg"
    },
    {
        id: 7,
        title: "deer",
        src:"./gallery/full_cam/Buck_42.jpg"
    },
    {
        id: 8,
        title: "deer",
        src:"./gallery/full_cam/Buck_41.jpg"
    },
    {
        id: 9,
        title: "deer",
        src:"./gallery/full_cam/Buck_40.jpg"
    },
    {
        id: 10,
        title: "deer",
        src:"./gallery/full_cam/Buck_1.jpg"
    },
    {
        id: 11,
        title: "deer",
        src:"./gallery/full_cam/Buck_2.jpg"
    },
    {
        id: 12,
        title: "deer",
        src:"./gallery/full_cam/Buck_3.jpg"
    },
    {
        id: 13,
        title: "deer",
        src:"./gallery/full_cam/Buck_4.jpg"
    },
    {
        id: 14,
        title: "deer",
        src:"./gallery/full_cam/Buck_5.jpg"
    },
    {
        id: 15,
        title: "deer",
        src:"./gallery/full_cam/Buck_6.jpg"
    },
    {
        id: 16,
        title: "deer",
        src:"./gallery/full_cam/Buck_7.jpg"
    },
    {
        id: 17,
        title: "deer",
        src:"./gallery/full_cam/Buck_8.jpg"
    },
    {
        id: 18,
        title: "deer",
        src:"./gallery/full_cam/Buck_9.jpg"
    },
    {
        id: 19,
        title: "deer",
        src:"./gallery/full_cam/Buck_10.jpg"
    },
    {
        id: 20,
        title: "deer",
        src:"./gallery/full_cam/Buck_11.jpg"
    },
    {
        id: 21,
        title: "deer",
        src:"./gallery/full_cam/Buck_12.jpg"
    },
    {
        id: 22,
        title: "deer",
        src:"./gallery/full_cam/Buck_13.jpg"
    },
    {
        id: 23,
        title: "deer",
        src:"./gallery/full_cam/Buck_14.jpg"
    },
    {
        id: 24,
        title: "deer",
        src:"./gallery/full_cam/Buck_15.jpg"
    },
    {
        id: 25,
        title: "deer",
        src:"./gallery/full_cam/Buck_16.jpg"
    },
    {
        id: 26,
        title: "deer",
        src:"./gallery/full_cam/Buck_17.jpg"
    },
    {
        id: 27,
        title: "deer",
        src:"./gallery/full_cam/Buck_18.jpg"
    },
    {
        id: 28,
        title: "deer",
        src:"./gallery/full_cam/Buck_19.jpg"
    },
    {
        id: 29,
        title: "deer",
        src:"./gallery/full_cam/Buck_20.jpg"
    },
    {
        id: 30,
        title: "deer",
        src:"./gallery/full_cam/Buck_21.jpg"
    },
    {
        id: 31,
        title: "deer",
        src:"./gallery/full_cam/Buck_22.jpg"
    },
    {
        id: 32,
        title: "deer",
        src:"./gallery/full_cam/11-1.jpg"
    },
    {
        id: 33,
        title: "deer",
        src:"./gallery/full_cam/11-2.jpg"
    },
    {
        id: 34,
        title: "deer",
        src:"./gallery/full_cam/11-3.jpg"
    },
    {
        id: 35,
        title: "deer",
        src:"./gallery/full_cam/11-4.jpg"
    }

]


