import React, { Component } from 'react';
import { Row, Container } from 'react-bootstrap';
import $ from 'jquery';
import Welcome from './Welcome.js';
import Hunting from './Hunting.js';
import Banner from './Banner.js';
import Contact from './Contact.js';
import Footer from './Footer.js';
import Gallery from './Gallery.js';
import './App.css';

$(document).ready(function () {
  // Add smooth scrolling to all links
  $(window).bind('load', function () {
    if (window.location.hash) {
      scrollto(window.location.hash)
    }
  })
  $(".navbar-nav a").on('click', function (event) {

    // Make sure this.hash has a value before overriding default behavior
   
      // Prevent default anchor click behavior


      // Store hash
      var hash = '#' + this.innerHTML;
      scrollto(hash);
      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area

  });

  function scrollto(hash) {
    try {
      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 800, function () {

        // Add hash (#) to URL when done scrolling (default click behavior)
        window.location.hash = hash;
      });
    } catch (exc) {

      console.log("Could Not Scroll");
    }
  }

});


class App extends Component {

  render() {

    return (
      <div id="mainContent">

        <Container fluid="true">
          <span id="Home"></span>
          <Row id="banner">
            <Banner />
          </Row>

          <Row className="Main" id="Welcome">
            <Welcome />
          </Row>
        </Container>
        <div id="para1" className="parallax"></div>
        <Container fluid="true">
          <Row className="Main" id="Hunting">
            <Hunting />
          </Row>
        </Container>
        <div id="para2" className="parallax"></div>

        <Gallery />

        <Container fluid="true">

          <Row className="Main" id="Contact">
            <Contact />
          </Row>


          
        </Container>
        <Footer />
      </div >
    )
  }
}

export default App;
