import React from 'react';
import { BANNERIMGS}  from '../data/flickrUrls';
import Carousel from 'react-bootstrap/Carousel'

const BannerImg = (props) => {
    const { title, src, id } = props.banner;
    return (
        <img  className="d-block w-100" src={src} alt={title} />

    )
}
const BannerImgs = () => (
    <div id="bannerContainer" className="img-fluid center-block">
        
        <div id="caruselContainer">
            
        <Carousel>
            {BANNERIMGS.map((banner) => {
                return (
                    <Carousel.Item key={banner.id}>
                        <BannerImg   banner={banner} />
                    </Carousel.Item>
                );
            })}
        </Carousel>
        </div>

    </div >
)

export default BannerImgs;
